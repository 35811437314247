export const API_URL = "https://api.qfiservices.com/api/v1/admin";

// dev-url : https://qfiapi.appgrowthcompany.com/api/v1/admin
// stagin url : http://api.qfiservices.com/

export const END_POINTS = {
  //authentication
  login: "login",
  forgot: "forgotPassword",
  otp: "verifyOtp",
  setPassword: "setPassword",
  changePassword: "changePassword",
  logout: "logout",

  //Profile
  getProfile: "profile",
  upload: "upload",
  updateProfile: "updateProfile",

  //category
  type: "type",

  //tags

  category: "category",

  //Interest Rate
  interestRate: "interestRate",

  //loan term
  loanTenure: "loanTenure",

  //manage_cars
  vehicle: "vehicle",

  //Banner
  banner: "banner",

  //faq
  faq: "faq",

  //CMS
  cms: "cms",

  //manage-testimonial

  testimonial: "testimonial",

  //manage-service station
  serviceStation: "serviceStation",

  //sub-admin
  subAdmin:"subAdmin",

  //forms
  form:"form",

  //user
  user:"user",
  //notification
  notification:"notification",
//csv
  userExports:"userExports",

  //dashboard
  dashboard:"dashboard",

  //graphs
  userGraph:"userGraph",

  //loanGraphs
  loanRequestGraph:"loanRequestGraph",

  //settings
  settings:"settings"  ,

  //manage loans
  loanRequest:"loanRequest",

  //customer support
  support:"support",
  reply:"reply",
  allExports:"allExports",

  document:"document",


  //criteria
  criteria:"criteria",


  //adminNotification
  adminNotification:"adminNotification",


  // payment ways
  payment:"payment",

  //Chats 
  chatRoom:"chatRoom",

  amountStatement:"amountStatement",
  
  creditSimulator:"creditSimulator",
  sortCriteria :"sortCriteria",
  loanReports :"loanReports",
  loanReportsExport:"loanReportsExport"

  
};
